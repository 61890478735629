import { useEffect } from 'react';
import { GetStaticProps, NextPage } from 'next';
import Head from 'next/head';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { Button } from 'design-system/atoms/button';
import { trackPage404 } from 'tracking/Mixpanel';
import { oTelCountPage } from 'tracking/OpenTelemetry';
import { useSettingsContext } from 'context';
import Image404 from 'components/404';
import { NOT_FOUND_PAGE, ROUTES } from 'common/data/Constants';
import { Page404ButtonWrapper, Page404ContentWrapper, Page404Label, Page404Wrapper } from 'styles/404.styled';

const Page404: NextPage = () => {
  const { t } = useTranslation('404');
  const { darkMode } = useSettingsContext();

  // Track Mixpanel page view
  useEffect(() => {
    trackPage404();
  }, []);

  // Count OpenTelemetry page view
  useEffect(() => {
    oTelCountPage(`/${NOT_FOUND_PAGE}`);
  }, []);

  return (
    <>
      <Head>
        <title>{t('head.title')}</title>
      </Head>

      <Page404Wrapper>
        <Page404ContentWrapper>
          <Image404 darkMode={darkMode} />
          <Page404Label darkMode={darkMode}>{t('page-not-found')}</Page404Label>
          <Page404ButtonWrapper>
            <Button
              href={ROUTES.HOME}
              darkMode={darkMode}
            >
              {t('take-me-home')}
            </Button>
          </Page404ButtonWrapper>
        </Page404ContentWrapper>
      </Page404Wrapper>
    </>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale ?? 'en', ['common', 'nav', '404'])),
    },
  };
};

export default Page404;
