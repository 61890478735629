import styled from '@emotion/styled';
import { mq } from 'styles/mediaqueries.styled';

interface Props {
  darkMode?: boolean;
}

export const Image404Wrapper = styled.div`
  position: relative;
  svg {
    /* MOBILE */
    width: ${(props: Props) => (props.darkMode ? '186px' : '195px')};
    height: ${(props: Props) => (props.darkMode ? '84px' : '88px')};
    margin-left: ${(props: Props) => (props.darkMode ? '0' : '6px')};
    /* TABLET */
    ${mq['tablet']} {
      width: ${(props: Props) => (props.darkMode ? '269px' : '277px')};
      height: ${(props: Props) => (props.darkMode ? '121px' : '129px')};
      margin-left: ${(props: Props) => (props.darkMode ? '0' : '8px')};
    }
  }
`;
