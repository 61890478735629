import { Image404Wrapper } from './404.styled';

interface Props {
  darkMode?: boolean;
}

const Image404LightMode = () => {
  return (
    <svg
      width="277"
      height="129"
      viewBox="0 0 277 129"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.23413 105.992V80.056L57.1492 11.8281H92.6008V75.6602H107V105.992H92.6008V128.259H59.1513V105.992H8.23413Z"
        fill="#121212"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.234131 97.9923V72.056L49.1492 3.82812H84.6008V67.6602H99.0002V97.9923H84.6008V120.259H51.1513V97.9923H0.234131ZM52.3634 10.0874H78.3416V73.9195H92.7409V91.7331H78.3416V114H57.4106V91.7331H6.49339V74.0679L52.3634 10.0874ZM57.4106 31.3153L26.979 73.9195H57.4106V31.3153ZM51.1513 50.8471L39.142 67.6602H51.1513V50.8471Z"
        fill="#121212"
      />
      <path
        d="M6.49341 91.7316H57.4106V113.999H78.3416V91.7316H92.7409V73.918H78.3416V10.0859H52.3634L6.49341 74.0665V91.7316ZM57.4106 31.3138V73.918H26.9791L57.4106 31.3138Z"
        fill="#FDBB9F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86 66C86 33.9675 111.967 8 144 8C176.033 8 202 33.9675 202 66C202 98.0325 176.033 124 144 124C111.967 124 86 98.0325 86 66Z"
        fill="#121212"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M136 6C107.281 6 84 29.2812 84 58C84 86.7188 107.281 110 136 110C164.719 110 188 86.7188 188 58C188 29.2812 164.719 6 136 6ZM78 58C78 25.9675 103.967 0 136 0C168.033 0 194 25.9675 194 58C194 90.0325 168.033 116 136 116C103.967 116 78 90.0325 78 58Z"
        fill="#121212"
      />
      <path
        d="M136 110C164.719 110 188 86.7188 188 58C188 29.2812 164.719 6 136 6C107.281 6 84 29.2812 84 58C84 86.7188 107.281 110 136 110Z"
        fill="#FDBB9F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M164.474 30.9885C165.57 32.5387 165.202 34.6838 163.652 35.7798L152.955 43.3423L163.652 50.9048C165.202 52.0007 165.57 54.1459 164.474 55.696C163.378 57.2462 161.233 57.6144 159.683 56.5185L145.016 46.1491C144.105 45.5048 143.563 44.4583 143.563 43.3423C143.563 42.2263 144.105 41.1797 145.016 40.5354L159.683 30.1661C161.233 29.0701 163.378 29.4383 164.474 30.9885Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M107.526 30.9885C108.622 29.4383 110.768 29.0701 112.318 30.1661L126.984 40.5354C127.896 41.1797 128.437 42.2263 128.437 43.3423C128.437 44.4583 127.896 45.5048 126.984 46.1491L112.318 56.5185C110.768 57.6144 108.622 57.2462 107.526 55.696C106.43 54.1459 106.799 52.0007 108.349 50.9048L119.045 43.3423L108.349 35.7798C106.799 34.6838 106.43 32.5387 107.526 30.9885Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.538 71.0248C122.308 67.4442 128.964 65.5469 135.755 65.5469C142.546 65.5469 149.202 67.4442 154.972 71.0248C160.743 74.6054 165.398 79.7269 168.413 85.8119C169.256 87.513 168.56 89.5753 166.859 90.4182C165.158 91.2611 163.096 90.5654 162.253 88.8643C159.806 83.9272 156.029 79.7717 151.347 76.8665C146.665 73.9613 141.265 72.4219 135.755 72.4219C130.245 72.4219 124.845 73.9613 120.163 76.8665C115.481 79.7717 111.704 83.9272 109.257 88.8643C108.414 90.5654 106.352 91.2611 104.651 90.4182C102.95 89.5753 102.254 87.513 103.097 85.8119C106.112 79.7269 110.768 74.6054 116.538 71.0248Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M178 105.992V80.056L226.915 11.8281H262.367V75.6602H276.766V105.992H262.367V128.259H228.917V105.992H178Z"
        fill="#121212"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M170 97.9923V72.056L218.915 3.82812H254.367V67.6602H268.766V97.9923H254.367V120.259H220.917V97.9923H170ZM222.129 10.0874H248.107V73.9195H262.507V91.7331H248.107V114H227.176V91.7331H176.259V74.0679L222.129 10.0874ZM227.176 31.3153L196.745 73.9195H227.176V31.3153ZM220.917 50.8471L208.908 67.6602H220.917V50.8471Z"
        fill="#121212"
      />
      <path
        d="M176.259 91.7316H227.176V113.999H248.107V91.7316H262.507V73.918H248.107V10.0859H222.129L176.259 74.0665V91.7316ZM227.176 31.3138V73.918H196.745L227.176 31.3138Z"
        fill="#FDBB9F"
      />
    </svg>
  );
};

const Image404DarkMode = () => {
  return (
    <svg
      width="269"
      height="121"
      viewBox="0 0 269 121"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.234131 97.9923V72.056L49.1492 3.82812H84.6008V67.6602H99.0002V97.9923H84.6008V120.259H51.1513V97.9923H0.234131ZM52.3634 10.0874H78.3416V73.9195H92.7409V91.7331H78.3416V114H57.4106V91.7331H6.49339V74.0679L52.3634 10.0874ZM57.4106 31.3153L26.979 73.9195H57.4106V31.3153ZM51.1513 50.8471L39.142 67.6602H51.1513V50.8471Z"
        fill="#121212"
      />
      <path
        d="M6.49341 91.7316H57.4106V113.999H78.3416V91.7316H92.7409V73.918H78.3416V10.0859H52.3634L6.49341 74.0665V91.7316ZM57.4106 31.3138V73.918H26.9791L57.4106 31.3138Z"
        fill="#FDBB9F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M136 6C107.281 6 84 29.2812 84 58C84 86.7188 107.281 110 136 110C164.719 110 188 86.7188 188 58C188 29.2812 164.719 6 136 6ZM78 58C78 25.9675 103.967 0 136 0C168.033 0 194 25.9675 194 58C194 90.0325 168.033 116 136 116C103.967 116 78 90.0325 78 58Z"
        fill="#121212"
      />
      <path
        d="M136 110C164.719 110 188 86.7188 188 58C188 29.2812 164.719 6 136 6C107.281 6 84 29.2812 84 58C84 86.7188 107.281 110 136 110Z"
        fill="#FDBB9F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M164.474 30.9885C165.57 32.5387 165.202 34.6838 163.652 35.7798L152.955 43.3423L163.652 50.9048C165.202 52.0007 165.57 54.1459 164.474 55.696C163.378 57.2462 161.233 57.6144 159.683 56.5185L145.016 46.1491C144.105 45.5048 143.563 44.4583 143.563 43.3423C143.563 42.2263 144.105 41.1797 145.016 40.5354L159.683 30.1661C161.233 29.0701 163.378 29.4383 164.474 30.9885Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M107.526 30.9885C108.622 29.4383 110.768 29.0701 112.318 30.1661L126.984 40.5354C127.896 41.1797 128.437 42.2263 128.437 43.3423C128.437 44.4583 127.896 45.5048 126.984 46.1491L112.318 56.5185C110.768 57.6144 108.622 57.2462 107.526 55.696C106.43 54.1459 106.799 52.0007 108.349 50.9048L119.045 43.3423L108.349 35.7798C106.799 34.6838 106.43 32.5387 107.526 30.9885Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.538 71.0248C122.308 67.4442 128.964 65.5469 135.755 65.5469C142.546 65.5469 149.202 67.4442 154.972 71.0248C160.743 74.6054 165.398 79.7269 168.413 85.8119C169.256 87.513 168.56 89.5753 166.859 90.4182C165.158 91.2611 163.096 90.5654 162.253 88.8643C159.806 83.9272 156.029 79.7717 151.347 76.8665C146.665 73.9613 141.265 72.4219 135.755 72.4219C130.245 72.4219 124.845 73.9613 120.163 76.8665C115.481 79.7717 111.704 83.9272 109.257 88.8643C108.414 90.5654 106.352 91.2611 104.651 90.4182C102.95 89.5753 102.254 87.513 103.097 85.8119C106.112 79.7269 110.768 74.6054 116.538 71.0248Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M169.948 97.9923V72.056L218.863 3.82812H254.315V67.6602H268.714V97.9923H254.315V120.259H220.866V97.9923H169.948ZM222.078 10.0874H248.056V73.9195H262.455V91.7331H248.056V114H227.125V91.7331H176.208V74.0679L222.078 10.0874ZM227.125 31.3153L196.693 73.9195H227.125V31.3153ZM220.866 50.8471L208.856 67.6602H220.866V50.8471Z"
        fill="#121212"
      />
      <path
        d="M176.208 91.7316H227.125V113.999H248.056V91.7316H262.455V73.918H248.056V10.0859H222.078L176.208 74.0665V91.7316ZM227.125 31.3138V73.918H196.693L227.125 31.3138Z"
        fill="#FDBB9F"
      />
    </svg>
  );
};

const Image404 = ({ darkMode }: Props) => {
  return (
    <Image404Wrapper darkMode={darkMode}>{darkMode ? <Image404DarkMode /> : <Image404LightMode />}</Image404Wrapper>
  );
};

export default Image404;
