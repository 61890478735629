import styled from '@emotion/styled';
import { NAV_GLOBAL_HEIGHT } from 'common/data/Constants';
import { mq } from './mediaqueries.styled';

interface Props {
  darkMode?: boolean;
}

export const Page404Wrapper = styled.div`
  display: flex;
  justify-content: center;
  /* TABLET */
  ${mq['tablet']} {
    display: flex;
    align-items: center;
    height: 100vh;
    height: 100dvh;
    margin-top: -116px;
  }
  /* DESKTOP */
  ${mq['desktop']} {
    margin-top: -186px;
  }
`;

export const Page404ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* MOBILE */
  margin-top: 38px;
  /* TABLET */
  ${mq['tablet']} {
    margin-top: 0;
  }
`;

export const Page404Label = styled.p`
  font-weight: 700;
  letter-spacing: -0.01em;
  text-align: center;
  /* MOBILE */
  font-size: 23px;
  line-height: 28px;
  margin-top: ${(props: Props) => (props.darkMode ? '-2px' : '-6px')};
  /* TABLET */
  ${mq['tablet']} {
    font-size: 32px;
    line-height: 36px;
    margin-top: ${(props: Props) => (props.darkMode ? '4px' : '-4px')};
  }
`;

export const Page404ButtonWrapper = styled.div`
  /* MOBILE */
  margin-top: 26px;
  /* TABLET */
  ${mq['tablet']} {
    margin-top: 37px;
  }
`;
